export const timeOptions = [
	`Anytime`,
	`Weekends Only`,
	`Anyday but Sunday`,
	`Anyday but Monday`,
	`Anyday but Tuesday`,
	`Anyday but Wednesday`,
	`Anyday but Thursday`,
	`Anyday but Friday`,
	`Anyday but Saturday`,
	`Monday-Friday 10am-5pm`,
	`Monday-Friday 11am-5pm`,
	`Monday-Friday 12am-5pm`,
	`Monday-Friday 1pm-5pm`,
	`Monday-Friday 2pm-5pm`,
	`Monday-Friday 3pm-5pm`,
	`Monday-Friday 5pm-7pm`,
	`Monday-Friday 6pm-8pm`,
	`Monday-Friday 7pm-9pm`,
	`Monday-Friday 9pm-11pm`,
];
